const getToken = (): { token: string | null; isLoggedIn: boolean } => {
  if (typeof window !== "undefined") {
    // Check if window is available
    try {
      const access_token = localStorage.getItem('access_token');
      return { token: access_token, isLoggedIn: !!access_token };
    } catch (error) {
      console.error('Error retrieving access token from localStorage:', error);
      return { token: null, isLoggedIn: false };
    }
  }

  // If window is not defined (during SSR), return default values
  return { token: null, isLoggedIn: false };
};

const setToken = (token: string): void => {
  if (typeof window !== "undefined") {
    // Check if window is available
    try {
      localStorage.setItem('access_token', token);
    } catch (error) {
      console.error('Error saving access token to localStorage:', error);
    }
  }
};

export {
  setToken, 
  getToken
}

