import { getToken } from './useToken';
import apiClient from "../api/apiClientInterceptor";
//import { showModal } from '../modalTrigger'; // Adjust the import path as needed

const getListings = async (cityName: string, page: number = 1, limit: number = 10) => {
  try {
    const { token, isLoggedIn } = await getToken();

    console.log('my token', token)
    
    // Optional: You can handle the case when the user is not logged in
    /*if (!isLoggedIn) {
      console.log('No token found');
      showModal();
      return;
    }*/

    // Make the API request with cityName, page, and limit
    const response = await apiClient.post(
      '/listings/getListings', 
      { city_name: cityName, page, limit },  // Pass page and limit as part of the payload
      {
        headers: {
          'Authorization': `Bearer ${token || ''}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error('Error fetching listings:', error);
    throw error; // Propagate the error for further handling
  }
};


const getDetails = async (data) => {
  try {
    const { token, isLoggedIn } = await getToken();

    const response = await apiClient.post('/listings/getDetails', data, {
      headers: {
        'Authorization': 'Bearer ' + token,
      }
    });

    return response.data;
  } catch (error) {
    console.error('Error fetching access token:', error);
    throw error; // Propagate the error for further handling
  }
};

export default {
  getListings,
  getDetails
};
