import React, { useEffect, useState, useRef } from 'react';

interface RatingBarsProps {
  rating: number;
}

const RatingBars: React.FC<RatingBarsProps> = ({ rating }) => {
  const maxRating = 5;
  const ratingPercentage = (rating / maxRating) * 100; // Calculate rating as a percentage
  const [width, setWidth] = useState(0); // Initial width is 0

  const barRef = useRef<HTMLDivElement>(null); // Reference to the bar container

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setWidth(ratingPercentage); // Animate the width when the component is in view
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the bar is visible
    );

    if (barRef.current) {
      observer.observe(barRef.current); // Observe the rating bar
    }

    return () => {
      if (barRef.current) {
        observer.unobserve(barRef.current); // Cleanup observer on component unmount
      }
    };
  }, [ratingPercentage]);

  // Conditionally set the color based on the rating
  const barColor = rating >= 4 ? 'bg-green-500' : 'bg-gray-400'; // Green if rating is 5, otherwise gray

  return (
    <div ref={barRef} className="w-full ml-4 rounded">
      <div
        className={`${barColor} h-2 rounded transition-all duration-500`} // Conditional color applied
        style={{ width: `${width}%` }} // Dynamically set width with state
      />
    </div>
  );
};

export default RatingBars;