import axios from 'axios';
import { getToken } from '../hooks/useToken';
//import { showModal } from './../modalTrigger';

// Create an Axios instance
const apiClient = axios.create({
  baseURL: `${process.env.REACT_APP_SERVER_ADDRESS}/${process.env.REACT_APP_APIBASEURL}`,
});

// Axios request interceptor
apiClient.interceptors.request.use(async (config) => {
  // Set the API key header
  const apiKey = process.env.REACT_APP_APIKEY;
  if (apiKey) {
    config.headers['x-api-key'] = apiKey;
  }

  // Retrieve the token asynchronously and set the Authorization header
  try {
    const token = await getToken(); // Assume getToken() returns a promise that resolves to the token
    if (token) {
      config.headers['Authorization'] = `Bearer ${token.token}`;
    }
  } catch (error) {
    console.error('Error retrieving token:', error);
  }

  return config;
}, (error) => {
  // Handle request error
  return Promise.reject(error);
});

// Axios response interceptor to handle 401 unauthorized responses
apiClient.interceptors.response.use(
  response => response,
  error => {
    console.log('Error received:', error);
    console.log('Error status:', error.response?.status);
    console.log('Error details:', error.response?.data);
    if (error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Show the login modal
      //showModal();
    }
    return Promise.reject(error);
  }
);

export default apiClient;
