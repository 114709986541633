import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import apiClient from '../api/apiClientInterceptor';

const AuthPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  // Function to extract token from the URL
  const getTokenFromUrl = () => {
    const queryParams = new URLSearchParams(location.search);
    return queryParams.get('token');
  };

  // Verify the token by sending it to the backend
  const verifyToken = async (token: string) => {

    try {
      const response = await apiClient.post('/auth/verify', { token });

      // Save the token in localStorage (or cookies)
      localStorage.setItem('access_token', response.data.access_token);

      // Redirect to a protected page (e.g., dashboard)
      navigate('/');
    } catch (err: any) {
      setError('Invalid or expired token. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const token = getTokenFromUrl();
    if (token) {
      verifyToken(token); // Verify the token
    } else {
      setError('No token found. Please try again.');
      setLoading(false);
    }
  }, [location]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return <div>Redirecting to your dashboard...</div>;
};

export default AuthPage;