import React, { useState } from 'react';
import { Listing } from '../models/Listing';
import RatingBars from './RatingBars';

interface PropertyCardProps {
  listing: Listing;
  onClick: () => void;
  onLoginClick: () => void; // New prop to trigger login footer
}

const PropertyCard: React.FC<PropertyCardProps> = ({ listing, onClick, onLoginClick }) => {
  const { rating } = listing;

  const [selectedRating, setSelectedRating] = useState<string | null>(null);

  const ratingCategories = [
    {
      label: 'Transportation',
      value: rating.AccessToPublicTransportation,
      description: 'Measures how close the property is to public transportation.',
    },
    {
      label: 'Amenities',
      value: rating.DistanceToAmenities,
      description: 'Rates the proximity to essential services such as shops, parks, etc.',
    },
    {
      label: 'Serendipitous',
      value: rating.Serendipitous,
      description: 'Indicates the potential for pleasant and unexpected encounters with other expats.',
    },
    {
      label: 'Safety',
      value: rating.Safety,
      description: 'Reflects the safety of the area based on crime rates and local environment.',
    },
    {
      label: 'Internet',
      value: rating.InternetConnectivity,
      description: 'Rates the quality and availability of internet services in the area.',
    },
    {
      label: 'Noise',
      value: rating.NoiseLevels,
      description: 'Measures the noise levels in and around the property.',
    },
  ];

  const handleRatingClick = (category: string) => {
    setSelectedRating((prev) => (prev === category ? null : category)); // Toggle info box
  };

  return (
    <div className="relative block card w-full bg-white shadow-xl cursor-pointer border border-gray-300 hover:shadow-2xl transition-shadow duration-300 rounded-lg">
      {/* Property Image */}
      <figure className="relative pb-2/3">
        <img
          src={listing.pictures[0]}
          className={`h-full w-full object-cover rounded-t-lg max-h-64 ${listing.disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`} // Add a disabled cursor if listing is disabled
          alt="Property"
          onClick={!listing.disabled ? onClick : undefined} // Disable click when listing is disabled
        />
      </figure>

      {/* Property Details */}
      <div className="card-body p-4 relative">
        <h2 className="card-title text-gray-800 font-semibold text-lg">
          {`$${new Intl.NumberFormat('en-US').format(listing.price)}`}
          {listing.status === 'new' && (
            <span className="badge ml-2 bg-green-100 text-green-800 px-2 py-1 rounded">
              New
            </span>
          )}
          {listing.mostViewed && (
            <span className="badge ml-2 bg-blue-100 text-white-800 px-2 py-1 rounded">
              Most Viewed
            </span>
          )}
        </h2>

        <p className="text-gray-600 mt-2">
          {listing.bedrooms !== 0 && listing.bathrooms !== 0 && (
            <>
              {listing.bedrooms} Beds • {listing.bathrooms} Baths •{' '}
            </>
          )}
          {listing.size} m²
        </p>

        {rating && (
          <div className="mt-4">
            <div className="flex flex-col space-y-4 relative">
              {ratingCategories.map((category, index) => (
                <div key={index} className="flex flex-col relative">
                  <div
                    className="flex justify-between items-center cursor-pointer"
                    onClick={() => handleRatingClick(category.label)}
                  >
                    {/* Blurred content if listing is disabled */}
                    <span
                      className={`w-1/3 text-gray-600 font-medium ${
                        listing.disabled ? 'filter blur-sm' : ''
                      }`}
                    >
                      {category.label}
                    </span>
                    <div
                      className={`w-2/3 flex items-center ${
                        listing.disabled ? 'filter blur-sm' : ''
                      }`}
                    >
                      <RatingBars rating={category.value} />
                    </div>
                  </div>

                  {/* Mini description box */}
                  {selectedRating === category.label && (
                    <div className="mt-2 text-sm text-gray-600 p-2 bg-gray-100 rounded-lg">
                      {category.description}
                    </div>
                  )}
                </div>
              ))}
              {/* Subscribe button if listing is disabled */}
              {listing.disabled && (
                <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-30">
                  <button className="bg-blue-500 text-white py-2 px-4 rounded-lg text-lg" onClick={onLoginClick}>
                    Subscribe
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PropertyCard;