import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Page';
import DesktopOverlay from './components/DesktopOverlay';
import AuthPage from './pages/Auth';

const App: React.FC = () => (
  <Router>
    <DesktopOverlay />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/auth" element={<AuthPage />} />
    </Routes>
  </Router>
);

export default App;