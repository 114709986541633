import React, { useState, useEffect } from 'react';
import { Listing } from '../models/Listing'; // Adjust path based on your project structure
import ImageCarousel from '../components/ImageCarousel'; // Import the ImageCarousel component
import Loader from './Loader';

interface ListingDetailsProps {
  listing: Listing; // Accept listing as a prop
  onClose: () => void; // Add a prop for closing the modal
}

const ListingDetails: React.FC<ListingDetailsProps> = ({ listing, onClose }) => {
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isVisible, setIsVisible] = useState(false); // State to manage slide-in effect
  const [startX, setStartX] = useState<number | null>(null); // To track swipe direction

  useEffect(() => {
    // Trigger the slide-in effect after component mounts
    setIsVisible(true);
    
    // Disable horizontal swipe but allow vertical scrolling
    const handleTouchMove = (e: TouchEvent) => {
      if (startX !== null) {
        const deltaX = e.touches[0].clientX - startX;
        if (Math.abs(deltaX) > Math.abs(e.touches[0].clientY)) {
          e.preventDefault(); // Prevent horizontal scrolling
        }
      }
    };

    document.body.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.body.removeEventListener('touchmove', handleTouchMove);
    };
  }, [startX]);

  const handleTouchStart = (e: React.TouchEvent) => {
    setStartX(e.touches[0].clientX); // Store the starting X position for swipe detection
  };

  const handleTouchEnd = () => {
    setStartX(null); // Reset touch start when the gesture ends
  };

  if (!listing) {
    return (
      <div className="flex justify-center items-center h-screen">
        <Loader />
      </div>
    ); // Provide a loading state if the listing is not available
  }

  const handleGetMoreInformation = () => {
    window.open(listing.href, '_blank');
  };

  return (
    <div
      className={`fixed inset-0 z-50 flex flex-col bg-white transform transition-all duration-500 ${
        isVisible ? 'translate-y-0 opacity-100' : 'translate-y-full opacity-0'
      }`}
      style={{ maxHeight: '100vh', overflowY: 'auto', overflowX: 'hidden' }} // Ensure modal doesn't overflow horizontally
      onTouchStart={handleTouchStart} // Track touch start
      onTouchEnd={handleTouchEnd} // Reset touch on end
    >
      {/* Carousel for images with close button */}
      <div className="relative w-full mb-4 min-h-64 max-h-64 overflow-hidden"> {/* Ensure the carousel takes full width */}
        <ImageCarousel pictures={listing.pictures} />
        {/* X Button to close modal */}
        <button
          className="absolute top-4 left-4 bg-gray-600 text-white rounded-full w-8 h-8 flex items-center justify-center focus:outline-none"
          onClick={onClose} // Handle close action
        >
          &times;
        </button>
      </div>

      {/* Content area that scrolls */}
      <div className="px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 mt-6 space-y-4 flex-grow overflow-y-auto">
        <div className="flex justify-between items-center">
          <h1 className="text-3xl font-bold">{`$${new Intl.NumberFormat('en-US').format(listing.price)}`}</h1>
        </div>
        <p className="text-lg text-gray-600">
          {/* Only show beds and baths if both are not equal to 0 */}
          {listing.bedrooms !== 0 && listing.bathrooms !== 0 && (
            <>
              {listing.bedrooms} Beds • {listing.bathrooms} Baths •{' '}
            </>
          )}
          {listing.size} m²
        </p>

        <div className="text-gray-700">
          {isDescriptionExpanded || (listing.description?.pros?.join(', ').length + listing.description?.cons?.join(', ').length) < 300 ? (
            <>
              <div>
                <strong>Pros:</strong>
                <ul>
                  {listing.description?.pros?.map((pro, index) => (
                    <li key={index}>{pro}</li>
                  ))}
                </ul>
              </div>
              <div>
                <strong>Cons:</strong>
                <ul>
                  {listing.description?.cons?.map((con, index) => (
                    <li key={index}>{con}</li>
                  ))}
                </ul>
              </div>
            </>
          ) : (
            <>
              <p>
                <strong>Pros:</strong> {listing.description?.pros?.slice(0, 2).join(', ')}...
              </p>
              <p>
                <strong>Cons:</strong> {listing.description?.cons?.slice(0, 2).join(', ')}...
              </p>
            </>
          )}

          {(listing.description?.pros?.length > 2 || listing.description?.cons?.length > 2) && (
            <button
              onClick={() => setIsDescriptionExpanded(!isDescriptionExpanded)}
              className="text-blue-500 hover:underline"
            >
              {isDescriptionExpanded ? 'Less' : 'More'}
            </button>
          )}
        </div>
      </div>

      {/* Button to get more information */}
      <div className="px-4 sm:px-6 md:px-8 lg:px-10 xl:px-12 w-full fixed bottom-0 left-0 bg-white py-4">
        <button
          onClick={handleGetMoreInformation}
          className="w-full bg-blue-600 text-white px-6 py-3 rounded-full hover:bg-blue-700 transition duration-300"
        >
          Get more info
        </button>
      </div>
    </div>
  );
};

export default ListingDetails;