import { City } from '../models/City';
//import getToken from './useToken';
import apiClient from "../api/apiClientInterceptor";

const getCities = async () => {
    try {
        /*const token = await getToken();
        if (!token) {
            console.log('No token found');
            //return;
        }*/

        const response = await apiClient.get('/cities/getCities', {
            headers: {
                'Authorization': 'Bearer ' + 1234,
            }
        })

        return response.data;
    }
    
    catch (error) {
        console.error('Error fetching access token:', error);
        throw error; // Propagate the error for further handling
    };
}

export default {
    getCities
} ;