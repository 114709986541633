import React, { useEffect, useState } from 'react';

const DesktopOverlay: React.FC = () => {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);

  useEffect(() => {
    // Function to check screen size
    const checkScreenSize = () => {
      setIsDesktop(window.innerWidth >= 512); // Set the threshold for desktop size (1024px or larger)
    };

    checkScreenSize(); // Initial check on component mount

    // Add event listener to handle window resize
    window.addEventListener('resize', checkScreenSize);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  if (!isDesktop) return null; // Do not render anything if not on a desktop screen

  return (
    <div className="fixed inset-0 w-full h-full bg-white bg-opacity-100 flex items-center justify-center z-50">
      {/* The overlay that covers the entire screen */}
      <div className="bg-white p-8 rounded-md shadow-xl text-center max-w-lg mx-auto">
        <h1 className="text-2xl font-bold text-black mb-4">Not Available on Desktop</h1>
        <p className="text-gray-700">
          Bektu.ai is not available for desktop sizes yet. Please use a mobile or tablet device to access the content.
        </p>
      </div>
    </div>
  );
};

export default DesktopOverlay;